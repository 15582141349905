// constants
import { TOASTER_MESSAGE } from 'lib/constants/messages'

export const errorMapping = {
  VALUE_NOT_IN_RANGE: TOASTER_MESSAGE.VALUE_NOT_IN_RANGE_MESSAGE,
  ATTRIBUTE_IN_USE: TOASTER_MESSAGE.ATTRIBUTE_IS_IN_USE_MESSAGE,
  [TOASTER_MESSAGE.ATTRIBUTE_IS_IN_USE]:
    TOASTER_MESSAGE.ATTRIBUTE_IS_IN_USE_MESSAGE,
  ATTRIBUTE_LINKED_TO_ATTRIBUTE_GROUP:
    TOASTER_MESSAGE.ATTRIBUTE_LINKED_TO_ATTRIBUTE_GROUP_MESSAGE,
  ATTRIBUTE_LOCALE_TITLE_NOT_UNIQUE:
    TOASTER_MESSAGE.ATTRIBUTE_LOCALE_TITLE_NOT_UNIQUE,
  ATTRIBUTE_LOCALE_TITLE_UNIQUE: TOASTER_MESSAGE.ATTRIBUTE_LOCALE_TITLE_UNIQUE,
}

export const CHANNEL_ERROR_MESSAGE = {
  LOCAL_ERROR_MESSAGE_HEADING: 'Account specific channel is not found!',
  LOCAL_ERROR_MESSAGE_DESCRIPTION: 'Please try again or contact your admin',
}
