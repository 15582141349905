import React from 'react'
import PropTypes from 'prop-types'
import theme from 'lib/theme/theme'

const LoadingIcon = ({ size, strokeColor, strokeWidth, ...restProps }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={`${size}px`}
    height={`${size}px`}
    viewBox='0 0 100 100'
    preserveAspectRatio='xMidYMid'
    {...restProps}
  >
    <circle
      cx='50'
      cy='50'
      fill='none'
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      r='40'
      strokeDasharray='188.49555921538757 64.83185307179586'
    >
      <animateTransform
        attributeName='transform'
        type='rotate'
        repeatCount='indefinite'
        dur='1.1904761904761905s'
        values='0 50 50;360 50 50'
        keyTimes='0;1'
      />
    </circle>
  </svg>
)
LoadingIcon.defaultProps = {
  size: 44,
  strokeColor: theme.palette.brand.primary.gray,
  strokeWidth: 10,
}

LoadingIcon.propTypes = {
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  strokeColor: PropTypes.string,
}
export default LoadingIcon
