/* eslint-disable */
import {
  FEATURE_FLAGS,
  featureFlagEnvMapping,
  featureFlagDomainMapping,
} from 'lib/constants/general-config'

import { UnleashClient } from 'unleash-proxy-client'
import { getTenantId } from './helper'

const proxyEnv = process.env.STAGE || 'dev02'
export const config = {
  url: `https://${featureFlagDomainMapping[proxyEnv]}.foundation.feature.fabric.inc/proxy`,
  clientKey: process.env.FEATURE_FLAG_CLIENT_KEY,
  appName: 'pim',
  environment: featureFlagEnvMapping[proxyEnv],
  disableRefresh: true,
  disableMetrics: true,
}

export let client = null
const isLocal = process.env.NODE_ENV === 'development'
export const startFeatureFlagsProxy = () => {
  if (client) {
    // client already initialized
    return
  }

  client = new UnleashClient(config)
  const tenantId = getTenantId()
  client.updateContext({ tenantId })
  client.start()
}

export const removeUnleashClient = () => {
  client = null
}

export const isFeatureActive = ({ flagName }) => {
  if (!client) {
    // Client not initialized
    startFeatureFlagsProxy()
  }

  const flag = client.isEnabled(flagName)
  /**
   * Disable Draft state for DS3 + V3 APIs
   */
  if (flagName === FEATURE_FLAGS.DRAFT_STATE) {
    const isV3Enabled = client.isEnabled(FEATURE_FLAGS.V3_TENANT)
    const isDS4Enabled = client.isEnabled(FEATURE_FLAGS.DS4)
    if (!isDS4Enabled && isV3Enabled) {
      return false
    }
  }

  if (flagName === FEATURE_FLAGS.UI_MULTICHANNEL) {
    const isV3Enabled = client.isEnabled(FEATURE_FLAGS.V3_TENANT)
    const isDS4Enabled = client.isEnabled(FEATURE_FLAGS.DS4)
    return isDS4Enabled && isV3Enabled && flag
  }

  // Show inline edit only for DS4 + V3 + draft tenant
  if (flagName === FEATURE_FLAGS.UI_DETAILS_PAGE_INLINE_EDIT) {
    const isV3Enabled = client.isEnabled(FEATURE_FLAGS.V3_TENANT)
    const isDS4Enabled = client.isEnabled(FEATURE_FLAGS.DS4)
    const isDraftEnabled = client.isEnabled(FEATURE_FLAGS.DRAFT_STATE)
    return isDS4Enabled && isV3Enabled && isDraftEnabled && flag
  }

  // Show AI chat integration only for DS4 + V3 + details page inline edit tenant
  if (flagName === FEATURE_FLAGS.UI_AI_CHAT_ENABLE) {
    const isDetailsInlinePageEditEnabled = client.isEnabled(
      FEATURE_FLAGS.UI_DETAILS_PAGE_INLINE_EDIT
    )
    const isV3Enabled = client.isEnabled(FEATURE_FLAGS.V3_TENANT)
    const isDS4Enabled = client.isEnabled(FEATURE_FLAGS.DS4)
    const isDraftEnabled = client.isEnabled(FEATURE_FLAGS.DRAFT_STATE)
    return (
      isDetailsInlinePageEditEnabled &&
      isV3Enabled &&
      isDS4Enabled &&
      isDraftEnabled &&
      flag
    )
  }

  // For local environment - uncomment below line if you want to verify the FF state
  // isLocal && console.log(`${flagName} - ${flag}`)
  return flag
}
