export const ITEM_BULK_ACTION = {
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DELETE_ALL: 'DELETE_ALL',
  UPDATE_ALL: 'UPDATE_ALL',
}

export const ITEM_ACTION = {
  CHANGE_PRIMARY_HIERARCHY_NODE: 'Change category node',
  CHANGE_CATEGORY: 'Change Category',
  UPDATE_ATTRIBUTES_VALUES: 'UPDATE_ATTRIBUTES_VALUES',
  PUBLISH_ITEM: 'PUBLISH_ITEM',
  UNPUBLISH_ITEM: 'UNPUBLISH_ITEM',
  DELETE: 'Delete',
  DELETE_ITEM: 'Delete',
  SAVE_AS_DRAFT: 'SAVE_AS_DRAFT',
  SAVE_UNSAVED_AS_DRAFT: 'SAVE_UNSAVED_AS_DRAFT',
  ITEM_SEARCH_PLACEHOLDER: 'Search by SKU or product name',
}

export const ITEM_ATTR_UPDATE_API_ACTION = {
  SET: 'SET',
  UNSET: 'UNSET',
}

export const DRAFT_STATE = {
  VIEW_DRAFT: 'View Draft',
  VIEW_LIVE: 'View Live',
  DRAFT: 'DRAFT',
  ITEM: 'ITEM',
  BUNDLE: 'BUNDLE',
  KEEP_DRAFT: 'KEEP_DRAFT',
  REPLACE_DRAFT: 'REPLACE_DRAFT',
}

export const ATTRIBUTE_GROUPS = {
  OTHERS: 'Others',
}

export const EXCLUDED_ATTRIBUTE = {
  ACTIVE: 'active',
}

export const ITEM_ERROR = {
  VALUE_NOT_CONTAIN: 'VALUE_NOT_CONTAIN',
  SAVE_AS_DRAFT_ERROR: 'SAVE_AS_DRAFT_ERROR',
  VALUE_NOT_IN_RANGE: 'VALUE_NOT_IN_RANGE',
  ITEM_SKU_ALREADY_EXISTS: 'ITEM_SKU_ALREADY_EXISTS',
}

export const CATLOG_TABLE_COLUMNS = {
  SKU: 'SKU',
  PRODUCT_NAME: 'Product name',
  CATEGORY: 'Category',
  STATUS: 'Status',
}
