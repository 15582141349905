import { useEffect, useState, lazy, Suspense } from 'react'
// import App from './pages/app'
// import DS4App from './ds4/app.component'
import {
  isFeatureActive,
  startFeatureFlagsProxy,
  removeUnleashClient,
  client as unleashClient,
} from 'lib/utils/feature-flag'
import { getTenantId } from 'lib/utils/helper'
import { useFeatureFlagControl } from './hooks/feature-flag'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'

// constant
import { FEATURE_FLAGS } from 'lib/constants/general-config'
import Spinner from 'ds4/components/spinner'
import { CopilotUIProvider, EmptyPage } from 'ds4-beta'

let client = new QueryClient()

//
const DS4App = lazy(() => import('./ds4/app.component'))
const DS3App = lazy(() => import('./pages/app'))

export default function Root(): JSX.Element {
  const [isUnleashClientReady, setIsUnleashClientReady] = useState(false)
  const [isUnleashClientFetchFailed, setIsUnleashClientFetchFailed] =
    useState(false)
  const [isDS4App, setIsDS4App] = useState(false)
  const [isV3Tenant, setIsV3Tenant] = useState(false)
  const [showUpdatedNavigation, setShowUpdatedNavigation] = useState(false)
  //In order to continue using the older version of the APP
  //set the isUseDS4 flag to false

  //Notice the anomoly of the code
  //we are using the useFeatureFlagControl, which eventually fetches the FF,
  //before the startFeatureFlagsProxy call, which sets up the unleash client,
  //this works because the useFeatureFlagControl, which internally calls isFeatureActive,
  //sets up the unleash client if it is not initially set up
  //we can start thinking of using the @unleash/proxy-client-react package
  //which give us useful flags like flagsReady via the useFlagsStatus hook
  //to check if the flags are ready to be used

  useEffect(() => {
    const tenantId = getTenantId()
    if (!tenantId) {
      setIsUnleashClientFetchFailed(true)
      setIsUnleashClientReady(true)
    } else {
      startFeatureFlagsProxy()
    }

    return removeUnleashClient
  }, [])

  useEffect(() => {
    if (unleashClient) {
      unleashClient.on('ready', () => {
        setIsDS4App(
          isFeatureActive({
            flagName: FEATURE_FLAGS.DS4,
          })
        )
        setIsV3Tenant(
          isFeatureActive({
            flagName: FEATURE_FLAGS.V3_TENANT,
          })
        )
        setShowUpdatedNavigation(
          isFeatureActive({
            flagName: FEATURE_FLAGS.UPDATED_NAVIGATION,
          })
        )
        setIsUnleashClientFetchFailed(false)
        setIsUnleashClientReady(true)
      })
      unleashClient.on('error', () => {
        setIsUnleashClientFetchFailed(true)
        setIsUnleashClientReady(true)
      })
    }
  }, [])

  if (!isUnleashClientReady) {
    return <Spinner />
  }

  if (isUnleashClientReady && isUnleashClientFetchFailed) {
    return <EmptyPage headerText='Something went wrong.' />
  }

  const AppContent = ({ children }) => {
    return (
      <div>
        <main className='app_wrapper' id='app_root'>
          {children}
        </main>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </div>
    )
  }
  return (
    <Suspense fallback={<Spinner />}>
      <CopilotUIProvider>
        <QueryClientProvider client={client}>
          {/* <AppContent> */}
          <BrowserRouter>
            {isDS4App ? (
              <DS4App isUiV3={isV3Tenant} />
            ) : (
              <DS3App
                isUiV3={isV3Tenant}
                showUpdatedNavigation={showUpdatedNavigation}
                unleashError={isUnleashClientFetchFailed}
              />
            )}
          </BrowserRouter>
          {/* </AppContent> */}
        </QueryClientProvider>
      </CopilotUIProvider>
    </Suspense>
  )

  return (
    <>
      {!isUnleashClientReady && <Spinner />}
      {isUnleashClientReady && (
        <Suspense fallback={<Spinner />}>
          <CopilotUIProvider>
            <QueryClientProvider client={client}>
              {isDS4App ? (
                <DS4App
                  isUiV3={isV3Tenant}
                  unleashError={isUnleashClientFetchFailed}
                />
              ) : (
                <DS3App
                  isUiV3={isV3Tenant}
                  showUpdatedNavigation={showUpdatedNavigation}
                  unleashError={isUnleashClientFetchFailed}
                />
              )}
            </QueryClientProvider>
          </CopilotUIProvider>
        </Suspense>
      )}
    </>
  )
}
