export const RTL_LOCALES = [
  'ar-DZ',
  'ar-BH',
  'ar-EG',
  'ar-IQ',
  'ar-JO',
  'ar-KW',
  'ar-LB',
  'ar-LY',
  'ar-MA',
  'ar-OM',
  'ar-QA',
  'ar-SA',
  'ar-SY',
  'ar-TN',
  'ar-AE',
  'ar-YE',
  'fa-IR',
  'he-IL',
]
