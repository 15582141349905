import { range, isEmpty, map, find, trim, has } from 'lodash'
import moment from 'moment-timezone'
import { format } from 'date-fns'
import { encode, decode } from 'html-entities'
import {
  ATTRIBUTE_TYPE,
  LOV_OPTION_TYPE,
  ATTRIBUTE_FORMULA_VALUE_MANUAL,
  ALLOWED_ATTRIBUTES_FOR_LOCALIZATION,
  ATTRIBUTE_GROUP_STATUS,
} from 'lib/constants/attributes'
import { RTL_LOCALES } from 'lib/constants/i18n'
import { ITEM_ATTR_UPDATE_API_ACTION } from 'lib/constants/items'
import { errorMapping } from 'lib/utils/errorMapping'
import { isFeatureActive } from './feature-flag'
import { FEATURE_FLAGS } from '../constants/general-config'
import { rbac } from '@teamfabric/copilot-utilities'

export const titleCase = (str) => {
  if (str && str.length > 0) {
    return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase())
  }
  return ''
}

export const getKeyValue = (objects, value) => {
  const result = objects.filter((obj) => {
    return obj.key === value
  })
  return result?.length > 0 ? result[0].value : undefined
}

export const calculateHasMore = (data) => {
  return (
    data.query &&
    data.query.limit + data.query.offset < data.query.count &&
    data.query.count > 0
  )
}

export const formatDate = (value, toSeconds) => {
  const options = {
    day: '2-digit',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
  }
  if (toSeconds) {
    options['second'] = '2-digit'
  }
  return new Date(value).toLocaleDateString('en-US', options)
}

export const timeSince = (date) => {
  var ms = new Date().getTime() - new Date(date).getTime()
  var seconds = Math.floor(ms / 1000)
  var minutes = Math.floor(seconds / 60)
  var hours = Math.floor(minutes / 60)
  var days = Math.floor(hours / 24)
  var months = Math.floor(days / 30)
  var years = Math.floor(months / 12)
  if (ms === 0) {
    return 'Just now'
  }
  if (seconds < 60) {
    return seconds + ' seconds ago'
  }
  if (minutes < 60) {
    return minutes + ' mins ago'
  }
  if (hours < 24) {
    return hours + ' hours ago'
  }
  if (days < 30) {
    return days + ' days ago'
  } else {
    return formatDate(date)
  }
  // if (months < 12) {
  //     return months + ' months Ago';
  // } else {
  //   return years + ' years Ago';
  // }
}

export const ErrorType = {
  NODE: 'node',
  FILTER: 'filter',
}

export const showBackendError = (error, errorType) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    if (typeof error.response.data.message === 'string') {
      return error.response.data.message
    }

    if (errorType) {
      return showStatusErrorMessage(error, errorType)
    }

    return error
  }
}

export const showStatusErrorMessage = (error, key) => {
  let message = {}
  if (
    error.response.data.message[0] &&
    error.response.data.message[0][key] &&
    error.response.data.message[0][key].statuses &&
    error.response.data.message[0][key].statuses[0] &&
    error.response.data.message[0][key].statuses[0].description
  ) {
    if (key === 'attribute') {
      message = { ...showAttributeStatusErrorMessage(error, key, message) }
    } else message = error.response.data.message[0][key].statuses[0].description
  } else if (error.response.data.message[0]?.description) {
    message = error.response.data.message[0].description
  } else if (
    error.response.data?.code &&
    error.response.data.message?.message
  ) {
    message = error.response.data.message.message
  }

  return !isEmpty(message) ? message : 'Error Occurred'
}

const showAttributeStatusErrorMessage = (error, key, message) => {
  const errorDescription =
    error.response.data.message[0][key].statuses[0].description

  message.message = errorDescription.startsWith?.(
    errorMapping.ATTRIBUTE_LOCALE_TITLE_NOT_UNIQUE
  )
    ? errorMapping.ATTRIBUTE_LOCALE_TITLE_UNIQUE
    : errorDescription

  message.attribute = error.response.data.message[0][key].id
  return message
}

export const attributeDateFormatParser = (date, dateFormat) => {
  const isV3Tenant = isFeatureActive({
    flagName: FEATURE_FLAGS.V3_TENANT,
  })
  return isV3Tenant
    ? date.toISOString()
    : format(new Date(date), attributeDateFormat(dateFormat))
}

export const attributeDateFormat = (format = 'MM/DD/YYYY') => {
  const parsedFormat = format.replace(/YYYY|DD/gi, function (el) {
    return el.toLowerCase()
  })
  // if (format === 'DD/MM/YYYY' || format === 'DD-MM-YYYY') {
  //   parsedFormat = 'MM/dd/yyyy'
  // } else {
  //   parsedFormat = format.replace(/YYYY|DD/gi, function (el) {
  //     return el.toLowerCase()
  //   })
  // }
  return parsedFormat
}

export const getDate = (value, format) => {
  if (
    format === 'DD/MM/YYYY' ||
    format === 'MM/DD/YYYY' ||
    format === 'DD-MM-YYYY' ||
    format === 'MM-DD-YYYY' ||
    format === 'YYYY/MM/DD' ||
    format === 'YYYY-MM-DD'
  ) {
    let dateSplit
    if (
      format === 'DD/MM/YYYY' ||
      format === 'MM/DD/YYYY' ||
      format === 'YYYY/MM/DD'
    )
      dateSplit = value.split('/')
    else dateSplit = value.split('-')
    if (format === 'MM/DD/YYYY' || format === 'MM-DD-YYYY') {
      return new Date(dateSplit[2], dateSplit[0] - 1, dateSplit[1])
    } else if (format === 'DD/MM/YYYY' || format === 'DD-MM-YYYY') {
      return new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0])
    } else {
      return new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2])
    }
  }
}

export const attributeTypeParser = ({ rowDetails }) => {
  const attributeType =
    rowDetails.type.includes('DECIMAL') ||
    rowDetails.type.includes('INTEGER') ||
    rowDetails.type.includes('NUMBER')
      ? 'Number only'
      : rowDetails.type.includes('OPTIONS')
      ? 'List of values'
      : rowDetails.type.includes('DATETIME')
      ? 'Date'
      : rowDetails.type.includes('DYNAMIC_LOV')
      ? 'Dynamic list of values'
      : rowDetails.type
  return titleCase(attributeType)
}

export const parseItemHierarchy = ({ rowDetails }) => {
  const breadcrumbs = rowDetails && rowDetails.breadcrumbs
  let breadcrumbsPath = ''
  if (
    breadcrumbs &&
    breadcrumbs[0] &&
    breadcrumbs[0]?.breadcrumbs?.length > 1
  ) {
    const withOutMaster = breadcrumbs[0].breadcrumbs?.slice(1)
    const path = withOutMaster.map((el) => el.name)
    const parentNode = path[0]
    if (path?.length > 1) {
      breadcrumbsPath = parentNode.concat('/.../' + breadcrumbs[0].name)
    } else {
      breadcrumbsPath = parentNode.concat('/' + breadcrumbs[0].name)
    }
  } else {
    breadcrumbsPath =
      breadcrumbs && breadcrumbs.length > 0 && breadcrumbs[0].name
  }
  return breadcrumbsPath
}

// Works for both single and multiple types
export const getListOfValuesForLocale = (
  values,
  locale,
  isLocalized = true
) => {
  const isNonDefaultLocale = locale != null && !locale.isDefault && isLocalized

  if (!Array.isArray(values)) {
    return isNonDefaultLocale
      ? values?.valueRaw
      : getFromLocalizedValues(values?.locales, locale)
  }

  return isNonDefaultLocale
    ? values
        .filter(({ locales }) => locales.length > 0)
        .map(({ locales }) => getFromLocalizedValues(locales, locale))
    : values.map((value) => value.valueRaw)
}

const getFromLocalizedValues = (locales, selectedLocale) => {
  if (!Array.isArray(locales) || !locales) return

  return locales.find(({ locale }) => locale === selectedLocale?.name)?.valueRaw
}

export const attributeMapper = (
  attribute,
  inheritedAttributes,
  selectedLocale = {}
) => {
  const type = attribute.attribute.type
  const isStatusAttribute =
    attribute.attribute.mapping === 'vendorStatus' ||
    attribute.attribute.mapping === 'active'
  const isFormula = !!attribute.attribute.formula
  const isFormulaManuallyOverWritten =
    attribute.attribute.formulaManuallyOverWritten
  const isDisabled = isStatusAttribute || attribute.isReadOnly
  const value = attribute.attribute.value
  const isFormulaStatusCode =
    attribute?.statuses?.[0]?.code === ATTRIBUTE_FORMULA_VALUE_MANUAL
  const invalidValueStatus =
    value?.statuses?.[0] ||
    value?.[0]?.statuses?.[0] ||
    attribute?.statuses?.[0]
  const canDisplayInvalidValue = type === ATTRIBUTE_TYPE.TEXT
  const useRawValue = type === ATTRIBUTE_TYPE.DATETIME
  const hasDetails = type === ATTRIBUTE_TYPE.DYNAMIC_LOV
  const initialValue =
    value &&
    (!invalidValueStatus || canDisplayInvalidValue || isFormulaStatusCode)
      ? useRawValue
        ? value.valueRaw
        : value.value
      : ''

  let displayedValue = Array.isArray(value)
    ? value.map((value) => value.valueRaw).join(',')
    : value?.valueRaw

  let displayedName = attribute.attribute.name

  /**
   * Under few scenarios an attribute won't be editable.
   * For default locale
   * 1. If the attribute is the status attribute, it won't be editable via edit form, it can only be set via the publish button only after all the mandatory attrbutes are filled.
   *  - isDisabled is the key that handles that.
   * For other locales
   * 1. Same scenario for the status attribute as in the case of defualt locale.
   *  - isDisbaled is the key that handles that.
   * 2. If the attribute is not localized, it will be visible in edit form under the selected locale, but won't be editable.
   *  - isLocalizedForSelectedLocale is the key that handles the baove case.
   * 3. If the attribute is localized for the selected locale, but the corresponding default locale value is yet not present for the same attribute, it won't be editable.
   *  - isEditLocaleDisabled is the key that handles that/
   */
  let isLocalizedForSelectedLocale =
    selectedLocale?.isDefault || isEmpty(selectedLocale)

  if (!isEmpty(selectedLocale) && !selectedLocale?.isDefault) {
    isLocalizedForSelectedLocale = attribute?.attribute?.localizable

    if (isLocalizedForSelectedLocale) {
      displayedName =
        attribute.attribute.locales?.find(
          (locale) => locale?.locale === selectedLocale?.name
        )?.name || displayedName

      if (value) {
        displayedValue =
          type === ATTRIBUTE_TYPE.OPTIONS &&
          attribute.attribute.optionSelectType === LOV_OPTION_TYPE.MULTIPLE
            ? getListOfValuesForLocale(value, selectedLocale)?.join(',')
            : getFromLocalizedValues(value?.locales, selectedLocale)
      }
    }
  }

  if (isStatusAttribute) {
    displayedValue = displayedValue === 'true' ? 'true' : 'false'
  }

  let isEditLocaleDisabled = false
  if (!isEmpty(selectedLocale) && !selectedLocale?.isDefault) {
    if (!attribute.attribute.value) {
      isEditLocaleDisabled = true
    }
  }

  // Should be used in combination with isLocalizedForSelectedLocale
  const isEditNonDefaultLocaleDisabled =
    isLocalizedForSelectedLocale &&
    type === ATTRIBUTE_TYPE.OPTIONS &&
    !isEmpty(selectedLocale) &&
    !selectedLocale.isDefault

  return {
    attribute: attribute.attribute,
    attributeGroupId: attribute.attributeGroupId,
    id: attribute.attributeId,
    mapping: attribute.attribute.mapping,
    action: '',
    itemId: '',
    error: invalidValueStatus ? invalidValueStatus.description : '',
    value: initialValue,
    displayedValue: displayedValue,
    displayedName: displayedName,
    order: attribute.order,
    hasDetails: hasDetails,
    isDisabled: isDisabled,
    isFormula: isFormula,
    isFormulaManuallyOverWritten: isFormulaManuallyOverWritten,
    isInherited:
      Array.isArray(inheritedAttributes) &&
      inheritedAttributes.includes(attribute.attributeId),
    isReadOnly: attribute.isReadOnly,
    isEditable: attribute.isEditable,
    isWorkflow: attribute.isWorkflow,
    isImpacted: attribute.isImpacted,
    isEditLocaleDisabled,
    isEditNonDefaultLocaleDisabled,
    isLocalizedForSelectedLocale,
  }
}

export const attributeMapperDraft = (
  attribute,
  inheritedAttributes,
  selectedLocale,
  isStatusAttributeLocalized
) => {
  const type = attribute.attribute.type
  const isStatusAttribute =
    attribute.attribute.mapping === 'vendorStatus' ||
    attribute.attribute.mapping === 'active'
  const isFormula = !!attribute.attribute.formula
  const isFormulaManuallyOverWritten =
    attribute.attribute.formulaManuallyOverWritten
  const isDisabled = isStatusAttribute || attribute.isReadOnly
  const value = selectedLocale?.isDefault
    ? attribute.attribute.value || attribute.value
    : attribute.attribute.value
  const isFormulaStatusCode =
    attribute?.statuses?.[0]?.code === ATTRIBUTE_FORMULA_VALUE_MANUAL
  const invalidValueStatus =
    value?.statuses?.[0] ||
    value?.[0]?.statuses?.[0] ||
    attribute?.statuses?.[0]
  const canDisplayInvalidValue = type === ATTRIBUTE_TYPE.TEXT
  const useRawValue = type === ATTRIBUTE_TYPE.DATETIME
  const hasDetails = type === ATTRIBUTE_TYPE.DYNAMIC_LOV

  let initialValue = ''
  if (
    value &&
    (!invalidValueStatus || canDisplayInvalidValue || isFormulaStatusCode)
  ) {
    initialValue = useRawValue ? value.valueRaw : value.value
  }

  let displayedValue = Array.isArray(value)
    ? value.map((value) => value.valueRaw).join(',')
    : value?.valueRaw

  let displayedName = attribute.attribute.name

  /**
   * Under few scenarios an attribute won't be editable.
   * For default locale
   * 1. If the attribute is the status attribute, it won't be editable via edit form, it can only be set via the publish button only after all the mandatory attrbutes are filled.
   *  - isDisabled is the key that handles that.
   * For other locales
   * 1. Same scenario for the status attribute as in the case of defualt locale.
   *  - isDisbaled is the key that handles that.
   * 2. If the attribute is not localized, it will be visible in edit form under the selected locale, but won't be editable.
   *  - isLocalizedForSelectedLocale is the key that handles the baove case.
   * 3. If the attribute is localized for the selected locale, but the corresponding default locale value is yet not present for the same attribute, it won't be editable.
   *  - isEditLocaleDisabled is the key that handles that/
   */
  let isLocalizedForSelectedLocale =
    selectedLocale?.isDefault || isEmpty(selectedLocale)

  if (!isEmpty(selectedLocale) && !selectedLocale?.isDefault) {
    isLocalizedForSelectedLocale = attribute?.attribute?.localizable

    if (isLocalizedForSelectedLocale) {
      displayedName =
        attribute.attribute.locales?.find(
          (locale) => locale?.locale === selectedLocale?.name
        )?.name || displayedName

      if (value) {
        displayedValue =
          type === ATTRIBUTE_TYPE.OPTIONS &&
          attribute.attribute.optionSelectType === LOV_OPTION_TYPE.MULTIPLE
            ? getListOfValuesForLocale(value, selectedLocale)?.join(',')
            : getFromLocalizedValues(value?.locales, selectedLocale)
      }
    }
  }

  if (isStatusAttribute) {
    displayedValue = displayedValue === 'true' ? 'true' : 'false'
  }

  let isEditLocaleDisabled = false
  if (!isEmpty(selectedLocale) && !selectedLocale?.isDefault) {
    if (isStatusAttributeLocalized) {
      if (has(attribute.attribute, 'value')) {
        isEditLocaleDisabled = !attribute.attribute.value
      } else {
        isEditLocaleDisabled = true
      }
    } else {
      if (!attribute.attribute.value?.value) {
        isEditLocaleDisabled = true
      }
    }
  }

  // Should be used in combination with isLocalizedForSelectedLocale
  const isEditNonDefaultLocaleDisabled =
    isLocalizedForSelectedLocale &&
    type === ATTRIBUTE_TYPE.OPTIONS &&
    !isEmpty(selectedLocale) &&
    !selectedLocale.isDefault

  if (Array.isArray(attribute.attribute.value?.locales)) {
    attribute.attribute.value.unmappedLocales =
      getUnmappedLocalesForAttributeGroup(attribute.attribute.value.locales)
  }

  return {
    attribute: attribute.attribute,
    attributeGroupId: attribute.attributeGroupId,
    id: attribute.attributeId || attribute.id,
    action: '',
    itemId: '',
    error: invalidValueStatus ? invalidValueStatus.description : '',
    value: initialValue,
    displayedValue: displayedValue,
    displayedName: displayedName,
    order: attribute.order,
    hasDetails: hasDetails,
    isDisabled: isDisabled,
    isFormula: isFormula,
    isFormulaManuallyOverWritten: isFormulaManuallyOverWritten,
    isInherited:
      Array.isArray(inheritedAttributes) &&
      inheritedAttributes.includes(attribute.attributeId),
    isReadOnly: attribute.isReadOnly,
    isEditable: attribute.isEditable,
    isWorkflow: attribute.isWorkflow,
    isImpacted: attribute.isImpacted,
    isEditLocaleDisabled,
    isEditNonDefaultLocaleDisabled,
    isLocalizedForSelectedLocale,
  }
}

export const nonLocalizedStatusAttributeMapper = (
  attribute,
  selectedLocale,
  isStatusAttributeLocalized
) => {
  const isAttributeLocalizable = attribute?.attribute?.localizable

  // By default. attribute.isEditLocaleDisabled is false
  attribute.isEditLocaleDisabled = false

  /*
    If status attribute is not localized, then if a user go to some other locale directly instead of default locale,
    then base display name and base display value gets set according to that non-default locale in first mapper.
    so we change it here back to default locale's name and value in 2nd mapper.
   */
  if (!isStatusAttributeLocalized) {
    attribute.displayedName = attribute.attribute.name
    attribute.displayedValue = attribute.value
  }

  if (
    isEmpty(attribute?.displayedValue) &&
    isEmpty(attribute?.value) &&
    isAttributeLocalizable &&
    !isEmpty(selectedLocale) &&
    !selectedLocale?.isDefault
  ) {
    attribute.isEditLocaleDisabled = true
  }

  if (
    attribute.attribute.mapping === 'sku' &&
    !isEmpty(selectedLocale) &&
    selectedLocale?.isDefault === false
  ) {
    attribute.isEditNonDefaultLocaleDisabled = true
  }

  if (!isEmpty(selectedLocale) && selectedLocale?.isDefault) {
    attribute.isEditLocaleDisabled = false
    attribute.isEditNonDefaultLocaleDisabled = false
  }

  if (attribute.attribute.type === ATTRIBUTE_TYPE.OPTIONS) {
    attribute.isEditNonDefaultLocaleDisabled =
      isAttributeLocalizable &&
      !isEmpty(selectedLocale) &&
      !selectedLocale.isDefault
  }

  // By default, isLocalizedForSelectedLocale should be true, default locale attributes are considered localized
  attribute.isLocalizedForSelectedLocale = true

  // Disable attribute based on its localizable status
  if (
    !attribute.attribute.localizable &&
    !isEmpty(selectedLocale) &&
    !selectedLocale?.isDefault
  ) {
    attribute.isLocalizedForSelectedLocale = false
  }

  return attribute
}
export const parseFormulaAttribute = (attribute) => {
  let dependentAttributes = attribute.formula.match(
    /attribute\('[a-z,0-9,A-Z,(),\s,\*,\-,_]+'\)/g
  )
  const attributeSearchPayload = []
  dependentAttributes = dependentAttributes.map((a) => {
    a = a.replace(/attribute\('/g, '').replace(/'\)/g, '')
    attributeSearchPayload.push({ name: a })
    return a
  })
  return attributeSearchPayload
}

export const getMappedColumnHeader = (list, map) => {
  const mapping = list?.find(
    (el) =>
      el.mapping.includes(map.toLowerCase()) ||
      el.mapping.includes(map.toUpperCase())
  )
  return mapping && mapping.attributeId ? mapping.name : map.toLowerCase()
}

export const getLastUpdated = (date) =>
  moment.tz(date, moment.tz.guess()).format('MMM. DD, YYYY, h:mm A z')

export const getTarget = (pathname, uppercase) => {
  const t =
    (pathname.includes('attributes/product') && 'PRODUCT') ||
    (pathname.includes('attributes/vendor') && 'VENDOR')
  if (uppercase) return t
  return t.toLocaleLowerCase()
}

export const getFormattedDate = (timeStamp) => {
  return {
    date: moment.tz(timeStamp, 'America/Los_Angeles').format('MMM, D, YYYY'),
    time: moment.tz(timeStamp, 'America/Los_Angeles').format('h:mm A'),
  }
}

/**
 * Get a range of year numbers from the center year ± the size
 * @param {number} size [10] - The number of year before/beyond the center of our range.
 * @param {number} centerYear [current year] - The center year of our  range.
 * @returns {array}
 */
export const getYearsRange = (
  size = 50,
  centerYear = new Date().getFullYear()
) => {
  const startYear = centerYear - size
  const endYear = centerYear + size + 1
  return range(startYear, endYear)
}

export const decodeString = (str) => decode(str)
export const encodeString = (str) => encode(str)

export const extractImageBufferFromString = (buffer) => {
  if (buffer.length < 11) return buffer
  return buffer.substring(10, buffer.length - 2)
}

export const getCalenderPlacementFlag = (
  index,
  dataLength,
  AttributeChunkSize = 2
) => {
  if (AttributeChunkSize > 2) {
    if (index < 3) return false
    return dataLength && Math.ceil(dataLength / 3) - 3 <= index
  }
  if (index < 5) return false
  return dataLength && dataLength / 2 - 6 < index
}

export const getXSiteContext = (isBrowser) => {
  // If STAGE env var is present then pick it from there else fallback to url
  let stage =
    process.env.STAGE ??
    ((isBrowser && new URL(window.location.origin).hostname?.split('.')[0]) ||
      'dev02')
  if (stage && stage === 'live') stage = 'prod01'

  // TODO STG01_OVERRIDE_CLEANUP
  if (isFeatureActive({ flagName: FEATURE_FLAGS.STG01_OVERRIDE }))
    stage = 'stg01'

  const xSiteContext = JSON.stringify({
    date: new Date().toISOString(),
    channel: 12,
    source: 'copilot',
    /*
      If logged in with identity v2 then use "accountId" from session storage
      otherwise use "account"
    */
    account: getTenantId(),
    stage: stage,
  })

  return xSiteContext
}

export const getStage = (isBrowser) => {
  let stage =
    process.env.STAGE ??
    ((isBrowser && new URL(window.location.origin).hostname?.split('.')[0]) ||
      'dev02')
  if (stage && stage === 'live') stage = 'prod01'
  return stage
}

export const getTenantId = () => {
  return window.localStorage.isLoggedInWithIdV2
    ? window.sessionStorage.getItem('accountId')
    : window.sessionStorage.getItem('account')
}

// Function to check if item/bundle form contains a value for mapped sku attribute or not
export const isMappedSkuDataPresent = (
  editedAttributes,
  mappedSkuAttribute
) => {
  const skuData = editedAttributes?.find(
    (attribute) =>
      attribute?.attribute?.attributeId ===
      mappedSkuAttribute?.attribute?.attributeId
  )

  // If sku data is there but value is empty it means sku value is needed
  return {
    skuData: skuData,
    isPresent: skuData !== undefined && trim(skuData?.value),
  }
}

export const getAttributeGroupStatus = (group, selectedLocale) => {
  // TODO: Use below once backend starts sending status for all the locales
  // return (isEmpty(selectedLocale) || selectedLocale?.isDefault) ? group.status : group.locales?.find(locale => locale.locale === selectedLocale?.name)?.status

  const notStarted = group.attributes.every((attribute) => {
    if (attribute.attribute.mapping !== 'active') {
      if (isEmpty(selectedLocale) || selectedLocale?.isDefault) {
        return !attribute.attribute.value
      }
      if (!isEmpty(selectedLocale) && !selectedLocale.isDefault) {
        if (attribute.attribute.localizable) {
          return !attribute.attribute.value?.locales?.find(
            (locale) => locale.locale === selectedLocale?.name
          )?.valueRaw
        } else {
          return !attribute.attribute.value
        }
      }
      return false
    }
  })

  if (notStarted) {
    return ATTRIBUTE_GROUP_STATUS.NOT_STARTED
  }

  const isCompleted = group.attributes.every((attribute) => {
    if (
      attribute.attribute.mapping !== 'active' &&
      attribute.attribute.validation.required
    ) {
      if (isEmpty(selectedLocale) || selectedLocale?.isDefault) {
        return !!attribute.attribute.value
      }
      if (!isEmpty(selectedLocale) && !selectedLocale.isDefault) {
        if (attribute.attribute.localizable) {
          return !!attribute.attribute.value?.locales?.find(
            (locale) => locale.locale === selectedLocale?.name
          )?.valueRaw
        } else {
          return !!attribute.attribute.value
        }
      }
    }
    return true
  })

  if (isCompleted) {
    return ATTRIBUTE_GROUP_STATUS.COMPLETED
  }

  const isInProgress = group.attributes.some((attribute) => {
    if (attribute.attribute.mapping !== 'active') {
      if (isEmpty(selectedLocale) || selectedLocale?.isDefault) {
        return !!attribute.attribute.value
      }
      if (!isEmpty(selectedLocale) && !selectedLocale.isDefault) {
        if (attribute.attribute.localizable) {
          return !!attribute.attribute.value?.locales?.find(
            (locale) => locale.locale === selectedLocale?.name
          )?.valueRaw
        } else {
          return !!attribute.attribute.value
        }
      }
    }
  })

  if (isInProgress) {
    return ATTRIBUTE_GROUP_STATUS.IN_PROGRESS
  }

  const isOptional = group.attributes.every((attribute) => {
    return !attribute.attribute.validation.required
  })

  if (isOptional) {
    return ATTRIBUTE_GROUP_STATUS.OPTIONAL
  }

  return ATTRIBUTE_GROUP_STATUS.NOT_STARTED
}

export const getAttributeGroupStatusDraft = (group, selectedLocale) => {
  const isOptionalCheck = group.attributes.every((attribute) => {
    return !attribute.attribute.validation.required
  })

  if (isOptionalCheck) {
    return ATTRIBUTE_GROUP_STATUS.OPTIONAL
  }

  const notStarted = group.attributes.every((attribute) => {
    if (attribute.attribute.mapping !== 'active') {
      if (isEmpty(selectedLocale) || selectedLocale?.isDefault) {
        return has(attribute, 'action')
          ? !attribute?.value?.length
          : !attribute.attribute.value
      }
      if (!isEmpty(selectedLocale) && !selectedLocale.isDefault) {
        if (attribute.attribute.localizable) {
          if (!isEmpty(attribute?.attribute?.value?.unmappedLocales)) {
            if (
              has(
                attribute?.attribute?.value?.unmappedLocales,
                selectedLocale?.name
              )
            ) {
              return !attribute?.attribute?.value?.unmappedLocales[
                selectedLocale?.name
              ]
            } else {
              return !attribute.attribute.value?.locales?.find(
                (locale) => locale.locale === selectedLocale?.name
              )?.valueRaw
            }
          } else {
            return !attribute.attribute.value?.locales?.find(
              (locale) => locale.locale === selectedLocale?.name
            )?.valueRaw
          }
        } else {
          return !attribute.attribute.value
        }
      }
      return false
    }
  })

  if (notStarted) {
    return ATTRIBUTE_GROUP_STATUS.NOT_STARTED
  }

  const isCompleted = group.attributes.every((attribute) => {
    if (
      attribute.attribute.mapping !== 'active' &&
      attribute.attribute.validation.required
    ) {
      if (isEmpty(selectedLocale) || selectedLocale?.isDefault) {
        return has(attribute, 'action')
          ? !!attribute?.value?.length
          : !!attribute.attribute.value
      }
      if (!isEmpty(selectedLocale) && !selectedLocale.isDefault) {
        if (attribute.attribute.localizable) {
          if (!isEmpty(attribute?.attribute?.value?.unmappedLocales)) {
            if (
              has(
                attribute?.attribute?.value?.unmappedLocales,
                selectedLocale?.name
              )
            ) {
              return !!attribute?.attribute?.value?.unmappedLocales[
                selectedLocale?.name
              ]
            } else {
              return !!attribute.attribute.value?.locales?.find(
                (locale) => locale.locale === selectedLocale?.name
              )?.valueRaw
            }
          } else {
            return !!attribute.attribute.value?.locales?.find(
              (locale) => locale.locale === selectedLocale?.name
            )?.valueRaw
          }
        } else {
          return !!attribute.attribute.value
        }
      }
    }
    return true
  })

  if (isCompleted) {
    return ATTRIBUTE_GROUP_STATUS.COMPLETED
  }

  const isInProgress = group.attributes.some((attribute) => {
    if (attribute.attribute.mapping !== 'active') {
      if (isEmpty(selectedLocale) || selectedLocale?.isDefault) {
        return !!attribute.action
          ? !!attribute.value
          : !!attribute.attribute.value
      }
      if (!isEmpty(selectedLocale) && !selectedLocale.isDefault) {
        if (attribute.attribute.localizable) {
          if (!isEmpty(attribute?.attribute?.value?.unmappedLocales)) {
            if (
              has(
                attribute?.attribute?.value?.unmappedLocales,
                selectedLocale?.name
              )
            ) {
              return !!attribute?.attribute?.value?.unmappedLocales[
                selectedLocale?.name
              ]
            } else {
              return !!attribute.attribute.value?.locales?.find(
                (locale) => locale.locale === selectedLocale?.name
              )?.valueRaw
            }
          } else {
            return !!attribute.attribute.value?.locales?.find(
              (locale) => locale.locale === selectedLocale?.name
            )?.valueRaw
          }
        } else {
          return !!attribute.attribute.value
        }
      }
    }
  })

  if (isInProgress) {
    return ATTRIBUTE_GROUP_STATUS.IN_PROGRESS
  }

  const isOptional = group.attributes.every((attribute) => {
    return !attribute.attribute.validation.required
  })

  if (isOptional) {
    return ATTRIBUTE_GROUP_STATUS.OPTIONAL
  }

  return ATTRIBUTE_GROUP_STATUS.NOT_STARTED
}

export const getNonLocalizedAttributeGroupStatusDraft = (
  group,
  selectedLocale
) => {
  const isOptionalCheck = group.attributes.every((attribute) => {
    return !attribute.attribute.validation.required
  })

  if (isOptionalCheck) {
    return ATTRIBUTE_GROUP_STATUS.OPTIONAL
  }

  const notStarted = group.attributes.every((attribute) => {
    if (attribute.attribute.mapping !== 'active') {
      if (isEmpty(selectedLocale) || selectedLocale?.isDefault) {
        return has(attribute, 'action')
          ? !attribute?.value?.length
          : !attribute.attribute.value
      }
      if (!isEmpty(selectedLocale) && !selectedLocale.isDefault) {
        if (attribute.attribute.localizable) {
          if (!isEmpty(attribute?.attribute?.value?.unmappedLocales)) {
            if (
              has(
                attribute?.attribute?.value?.unmappedLocales,
                selectedLocale?.name
              )
            )
              return !attribute?.attribute?.value?.unmappedLocales[
                selectedLocale?.name
              ]
          } else {
            return !attribute.attribute.value?.locales?.find(
              (locale) => locale.locale === selectedLocale?.name
            )?.valueRaw
          }
        } else {
          return !attribute.attribute.value
        }
      }
      return false
    }
  })

  if (notStarted) {
    return ATTRIBUTE_GROUP_STATUS.NOT_STARTED
  }

  const isCompleted = group.attributes.every((attribute) => {
    if (
      attribute.attribute.mapping !== 'active' &&
      attribute.attribute.validation.required
    ) {
      if (isEmpty(selectedLocale) || selectedLocale?.isDefault) {
        return has(attribute, 'action')
          ? !!attribute?.value?.length
          : !!attribute.attribute.value
      }
      if (!isEmpty(selectedLocale) && !selectedLocale.isDefault) {
        if (attribute.attribute.localizable) {
          if (!isEmpty(attribute?.attribute?.value?.unmappedLocales)) {
            if (
              has(
                attribute?.attribute?.value?.unmappedLocales,
                selectedLocale?.name
              )
            ) {
              return !!attribute?.attribute?.value?.unmappedLocales[
                selectedLocale?.name
              ]
            } else if (
              has(attribute?.attribute?.value?.locales, selectedLocale?.name)
            ) {
              return !!attribute.attribute.value?.locales?.find(
                (locale) => locale.locale === selectedLocale?.name
              )?.valueRaw
            } else {
              return false
            }
          } else {
            return !!attribute.attribute.value?.locales?.find(
              (locale) => locale.locale === selectedLocale?.name
            )?.valueRaw
          }
        } else {
          return (
            !!attribute.attribute.value ||
            !!attribute.value ||
            !!attribute.displayedValue
          )
        }
      }
    }
    return true
  })

  if (isCompleted) {
    return ATTRIBUTE_GROUP_STATUS.COMPLETED
  }

  const isInProgress = group.attributes.some((attribute) => {
    if (attribute.attribute.mapping !== 'active') {
      if (isEmpty(selectedLocale) || selectedLocale?.isDefault) {
        return !!attribute.action
          ? !!attribute.value
          : !!attribute.attribute.value
      }
      if (!isEmpty(selectedLocale) && !selectedLocale.isDefault) {
        if (attribute.attribute.localizable) {
          if (!isEmpty(attribute?.attribute?.value?.unmappedLocales)) {
            if (
              has(
                attribute?.attribute?.value?.unmappedLocales,
                selectedLocale?.name
              )
            )
              return !!attribute?.attribute?.value?.unmappedLocales[
                selectedLocale?.name
              ]
          } else {
            return !!attribute.attribute.value?.locales?.find(
              (locale) => locale.locale === selectedLocale?.name
            )?.valueRaw
          }
        } else {
          return !!attribute.attribute.value
        }
      }
    }
  })

  if (isInProgress) {
    return ATTRIBUTE_GROUP_STATUS.IN_PROGRESS
  }

  const isOptional = group.attributes.every((attribute) => {
    return !attribute.attribute.validation.required
  })

  if (isOptional) {
    return ATTRIBUTE_GROUP_STATUS.OPTIONAL
  }

  return ATTRIBUTE_GROUP_STATUS.NOT_STARTED
}

export const getMappedLocales = (locales = {}) => {
  if (isEmpty(locales)) return []

  return map(locales, (value, locale) => {
    return { locale: locale, name: value }
  }).filter((locale) => !!locale.name)
}

export const getMappedLocalesForAttributeGroup = (locales = {}) => {
  if (isEmpty(locales)) return []

  return map(locales, (value, locale) => {
    return { locale: locale, value: value }
  })
}

export const getUnmappedLocalesForAttributeGroup = (locales = []) => {
  if (!Array.isArray(locales)) return {}

  const unmappedLocales = {}
  locales.forEach(({ locale, valueRaw }) => {
    unmappedLocales[locale] = valueRaw
  })
  return unmappedLocales
}

export const getUnmappedLocales = (locales = []) => {
  if (!Array.isArray(locales)) return {}

  const unmappedLocales = {}
  locales.forEach(({ locale, name }) => {
    unmappedLocales[locale] = name
  })
  return unmappedLocales
}

export const getAttributeLabel = (attribute, selectedLocale) => {
  if (
    !isEmpty(selectedLocale) &&
    !selectedLocale?.isDefault &&
    attribute?.attribute?.localizable
  ) {
    const requiredLocale = attribute?.attribute?.locales?.find(
      (l) => l.locale === selectedLocale.name
    )

    if (isEmpty(requiredLocale))
      return attribute.displayedName || attribute.attribute.name

    return requiredLocale.name
  }

  return attribute.displayedName || attribute.attribute.name
}

export const isRTLLocale = (locale) => {
  const mappedRTLLocales = RTL_LOCALES.map((locale) => mapLocaleName(locale))
  return mappedRTLLocales.includes(locale)
}

export const getLocalePrefix = (selectedLocale) =>
  selectedLocale?.name ? '(' + selectedLocale?.name + ')' : ''

export const getDefaultLocale = (locales) => {
  return find(locales, (locale) => locale.isDefault)?.name
}

export const mapLocaleName = (locale) => {
  if (typeof locale !== 'string') return locale

  let mappedLocalName = locale.split('-')
  if (mappedLocalName.length > 1) {
    return `${mappedLocalName[0].toLowerCase()}-${mappedLocalName[1].toUpperCase()}`
  }

  return locale
}

export const showLocalizationOption = (attribute) => {
  if (!attribute || typeof attribute !== 'object') return false

  const { type, mapping } = attribute

  // If attribute is not in given list of allowed attributes then dont show form
  let isAllowedToLocalize = ALLOWED_ATTRIBUTES_FOR_LOCALIZATION.includes(type)

  if (!isAllowedToLocalize) return false

  /*
    If attribute is boolean type but it it is not mapped to "active" attribute
    then dont show form
   */
  if (ATTRIBUTE_TYPE.BOOLEAN === type && mapping !== 'active') {
    return false
  }

  /*
    If attribute is text type and it is already mapped to "sku" attribute then
    don't show form
   */
  if (ATTRIBUTE_TYPE.TEXT === type && mapping === 'sku') {
    return false
  }

  // Show form in every other case as we have covered all falsy cases above
  return true
}

export const showI18nUi = (accountLocales = []) => {
  const hasDefaultLocale = accountLocales.find((locale) => locale.isDefault)
  const isI18nActive = isFeatureActive({
    flagName: FEATURE_FLAGS.I18N_LOCALIZATION,
  })

  return (
    isI18nActive &&
    !isEmpty(accountLocales) &&
    hasDefaultLocale &&
    accountLocales.length > 1
  )
}

export const globalPIMAccessPermissionCheck = (allowedPermissions) => {
  const isRbacActive = isFeatureActive({
    flagName: FEATURE_FLAGS.RBAC,
  })

  const sessionPermissions = window.localStorage.getItem('userperm')

  if (!isRbacActive || isEmpty(sessionPermissions)) {
    return true
  }

  if (isRbacActive) {
    const { hasAccess } = rbac.checkPermission({
      allowedPermissions,
    })

    if (hasAccess) {
      return true
    }
  }
}

export const checkForLocalePresenceInPayload = (payload) => {
  const showLocalDeleteWarning = payload.filter(
    (item) =>
      (item.action === ITEM_ATTR_UPDATE_API_ACTION.UNSET &&
        !isEmpty(item?.locales) &&
        isEmpty(item?.value)) ||
      item?.isOptionsLocalized
  )
  return !isEmpty(showLocalDeleteWarning)
}

export const transformAttribute = ({ string }) => {
  if (!string) return string

  if (typeof string !== 'string') return string

  if (string === '<p><br></p>') {
    return ' '
  } else if (string.startsWith('<p>') && string.endsWith('</p>')) {
    return string.slice(0, -4).replace('<p>', '')
  } else {
    return string
  }
}
export const checkForLocalePresenceInPayloadDraft = (payload) => {
  let isOptionLocalizedPresent = false
  const attributes = payload.filter((item) => {
    if (item?.isOptionsLocalized) {
      isOptionLocalizedPresent = true
    }
    return (
      item.action === ITEM_ATTR_UPDATE_API_ACTION.UNSET &&
      item.attribute.localizable
    )
  })

  if (isOptionLocalizedPresent) {
    return true
  }

  return attributes.some((attribute) => {
    return Object.values(
      attribute?.attribute?.value?.unmappedLocales || {}
    ).some((item) => !!item)
  })
}

export const transformLocalePayload = (payload) => {
  payload.forEach((attribute) => delete attribute?.isOptionsLocalized)
  return payload
}
