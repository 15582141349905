import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import NewLoadingIcon from '../svg/new-loading'
import { StyledSpinnerContainer } from './styles'
import { Loading } from '@teamfabric/copilot-ui'
const renderSpinner = (props) => {
  const {
    spinnerProps,
    className,
    show,
    strokeWidth,
    strokeColor,
    variant,
    // small,
    id,
    size,
    ...rest
  } = props
  return (
    <StyledSpinnerContainer
      // small={small}
      data-variant={variant}
      // {...rest}
      data-testid={id ? id : 'fs-spinner'}
    >
      {/* <NewLoadingIcon /> */}
      <Loading
        size={size}
        className={className}
        show={show}
        strokeColor={strokeColor}
        strokeWidth={strokeWidth}
        // data-testid={id ? id : 'fs-spinner'}
        {...rest}
      />
    </StyledSpinnerContainer>
  )
}

const Spinner = (props) => {
  return props.variant === 'fullScreen' && typeof window !== undefined
    ? createPortal(renderSpinner(props), document.body)
    : renderSpinner(props)
}

Spinner.defaultProps = {
  variant: 'fullScreen',
  // small: false,
  color: 'grey',
  size: 44,
  show: true,
}

Spinner.propTypes = {
  variant: PropTypes.oneOf(['normal', 'fullScreen', 'block', 'fill']),
  // small: PropTypes.bool,
  color: PropTypes.oneOf(['grey', 'primary', 'secondary', 'success', 'error']),
  show: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  strokeColor: PropTypes.string,
}

export default Spinner
