export const TOASTER_MESSAGE = {
  ITEM_DELETED: 'Item deleted successfully',
  BUNDLE_DELETED: 'Bundle deleted successfully',
  CHILD_ITEM_REMOVED: 'Child item removed successfully from parent',
  BACKGROUND_JOB_API_RESPONSE: 'Background job has been pushed.',
  BULK_UPDATE_ATTRIBUTE_VALUES:
    'Your bulk product attribute value update has begun. You will receive an email when the process completes.',
  BULK_CHANGE_CATEGORY:
    'Your bulk category change has begun. You will receive an email when the process completes.',
  ATTRIBUTE_IS_IN_USE: 'Attribute is in use',
  ATTRIBUTE_IS_IN_USE_MESSAGE:
    'This attribute is assigned to a category. You must remove it from the category before deleting.',
  VALUE_NOT_IN_RANGE_MESSAGE:
    'The value entered is not within the required range for this attribute',
  ATTRIBUTE_LINKED_TO_ATTRIBUTE_GROUP_MESSAGE:
    'This attribute is being used in an Attribute Group. You must remove it from the Attribute Group before deleting.',
  ATTRIBUTE_LOCALE_TITLE_NOT_UNIQUE:
    'The locale object array contains a duplicate value',
  ATTRIBUTE_LOCALE_TITLE_UNIQUE: 'Please enter a unique title for each locale',
  DELETE_ATTRIBUTE_VALUE_TITLE: 'Delete Attribute Value',
  DELETE_ATTRIBUTE_VALUE_MESSAGE:
    'Are you sure you want to delete this attribute value? Product requires values for the default locale to unlock values in other locales. This action will delete any values you have entered for this attribute in other locales.',
  DELETE_ATTRIBUTE_VALUE_MESSAGE_DRAFT:
    'Are you sure you want to delete this attribute value? Product requires values for the default locale to unlock values in other locales. You have removed value from default locale of attribute(s). This action will not allow you to update such attribute(s) in other locales.',
}

export const TOOLTIP_MESSAGES = {
  NON_LOCALIZED_ACTIVE_ATTR_DISABLED_PUBLISH_BUTTON:
    'Complete mandatory fields for all locales to publish.',
  NON_LOCALIZED_ACTIVE_ATTR_DISABLED_PUBLISH_BUTTON_DRAFT:
    'Complete mandatory fields to publish.',
}

export const PERMISSION_MESSAGES = {
  NO_PIM_ACCESS_HEADING: "You don't have permission to view this",
  NO_PIM_ACCESS_SUB_HEADING: 'Please request your admin for access',
}

export const ERROR_MESSAGES = {
  ATTRIBUTE_LOCALE_UNIQUE_TITLE: 'Attribute Title must be unique',
}

export const EMPTY_CTA_MESSAGE = {
  EMPTY_ITEM_MESSAGE: 'Add items to your catalog.',
  EMPTY_BUNDLE_MESSAGE:
    'Add items to this bundle to encourage your customers to purchase items in groups.',
  EMPTY_ALTERNATE_HIERARCHY_HEADING: 'You have no collections',
  EMPTY_ALTERNATE_HIERARCHY_MESSAGE:
    'Create collections to group items for different use cases.',
}

export const NO_SEARCH_RESULT = {
  HEADER_TEXT: 'No results found.',
  DESCRIPTION:
    'Try searching for something more general, change filters or check for spelling mistakes.',
}

export const JOBS_TOAST_MESSAGE = {
  IMPORT_STARTED: 'Your import has started. We will notify you when it’s done.',
  EXPORT_STARTED: 'Your export has started. We will notify you when it’s done.',
}

export const TOAST_MESSAGE = {
  IMPORT_STARTED:
    'Your import has been queued. Please visit import history to see the progress',
  EXPORT_STARTED:
    'Your export has been queued, you will receive an email once the export is ready',
}
