export const ATTRIBUTE_TYPE = {
  NESTED: 'NESTED',
  TEXT: 'TEXT',
  BOOLEAN: 'BOOLEAN',
  SERIAL: 'SERIAL',
  DECIMAL: 'DECIMAL',
  INTEGER: 'INTEGER',
  DATETIME: 'DATETIME',
  ASSET: 'ASSET',
  OPTIONS: 'OPTIONS',
  DYNAMIC_LOV: 'DYNAMIC_LOV',
}

export const LOV_SOURCE = {
  LOCATION: 'LOCATION',
  CONTACT: 'CONTACT',
}

export const ATTRIBUTE_TYPE_TARGET = {
  ITEM: 'ITEM',
  NODE: 'NODE',
}

export const LOV_OPTION_TYPE = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
}

export const ATTRIBUTE_TYPES_DESCRIPTION = {
  TEXT: 'Text',
  DATETIME: 'Date',
  SERIAL: 'Serial',
  BOOLEAN: 'Boolean',
  OPTIONS: 'List of values',
  DECIMAL: 'Number only',
  INTEGER: 'Number only',
  NUMBER: 'Number only',
  DYNAMIC_LOV: 'Dynamic List Of Values',
}

export const TEXT_ATTRIBUTE_SUBTYPES = {
  HTML: 'HTML',
}

export const ATTRIBUTE_FORMULA_VALUE_MANUAL = 'FORMULA_VALUE_MANUAL'

export const ATTRIBUTE_GROUP_STATUS = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN PROGRESS',
  NOT_STARTED: 'NOT STARTED',
  OPTIONAL: 'OPTIONAL',
}

export const MAPPED_ATTRIBUTES = {
  ACTIVE: 'active',
  SKU: 'sku',
}

export const ALLOWED_ATTRIBUTES_FOR_LOCALIZATION = [
  // List of attribute types which can be localized,just add more here when needed
  ATTRIBUTE_TYPE.TEXT,
  ATTRIBUTE_TYPE.BOOLEAN,
  // LOV
  ATTRIBUTE_TYPE.OPTIONS,
]

export const NUM_DATE_TYPES = [
  'DATETIME',
  'INTEGER',
  'SERIAL',
  'DECIMAL',
  'NUMBER',
]
