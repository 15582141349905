export default {
  breakpoints: {
    keys: ['xsm', 'sm', 'md', 'desktop', 'lg', 'xl', 'xxl'],
    values: {
      xsm: 580,
      sm: 780,
      md: 1024,
      desktop: 1280,
      lg: 1440,
      xl: 1800,
      xxl: 1960,
    },
  },
  direction: 'ltr',
  palette: {
    brand: {
      primary: {
        charcoal: '#121213',
        gray: '#737F8F',
        white: '#FFFFFF',
      },
      secondary: {
        purple: '#8458F6',
        pink: '#E84869',
        yellow: '#F7CE46',
        green: '#58BF86',
        blue: '#57BFF9',
      },
    },
    ui: {
      gray1: '#384454',
      gray2: '#737F8F',
      gray3: '#737373',
      gray4: '#E5E5E5',
      gray5: '#E3E5E9',
      gray6: '#E2E5E9',
      gray7: '#A0A0A1',
      gray8: '#B9BFC7',
      gray9: '#F1F2F4',
      gray10: '#D5D5D5',
      yellow: '#FD9F1C',
      green: '#008A1E',
      red: '#D50000',
      blue: '#0D62FF',
      blueviolet: '#8A2BE2',
      hover: '#3D82FF',
    },
  },
  border: 'rgba(115, 127, 143, 0.2)',
  typography: {
    fontFamily: 'Gilroy',
    fontSize: 14,
    h1: {
      fontSize: '60px',
      letterSpacing: 'normal',
      lineHeight: '66px',
      fontStyle: 'normal',
    },
    h2: {
      fontSize: '48px',
      letterSpacing: 'normal',
      lineHeight: '52px',
      fontStyle: 'normal',
    },
    h3: {
      fontSize: '32px',
      letterSpacing: 'normal',
      lineHeight: '38px',
      fontStyle: 'normal',
    },
    h4: {
      fontSize: '18px',
      letterSpacing: 'normal',
      lineHeight: '21px',
      fontStyle: 'normal',
    },
    h5: {
      fontSize: '16px',
      letterSpacing: 'normal',
      lineHeight: '18px',
      fontStyle: 'normal',
    },
    h6: {
      fontSize: '13px',
      letterSpacing: '0.5px',
      lineHeight: '16px',
      fontStyle: 'normal',
    },
    subtitle1: {
      fontSize: '32px',
      letterSpacing: 'normal',
      lineHeight: '38px',
      fontStyle: 'normal',
    },
    subtitle2: {
      fontSize: '24px',
      letterSpacing: 'normal',
      lineHeight: '28px',
      fontStyle: 'normal',
    },
    subtitle3: {
      fontSize: '21px',
      letterSpacing: '0.5px',
      lineHeight: '25px',
      fontStyle: 'normal',
    },
    body: {
      fontSize: '18px',
      letterSpacing: 'normal',
      lineHeight: '27px',
      fontStyle: 'normal',
    },
    kicker: {
      fontSize: '12px',
      letterSpacing: 'normal',
      lineHeight: '14px',
      fontStyle: 'normal',
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: '12px',
      letterSpacing: 'normal',
      lineHeight: '18px',
      fontStyle: 'normal',
    },
    link: {
      fontSize: '14px',
      letterSpacing: 'normal',
      lineHeight: '16px',
      fontStyle: 'normal',
    },
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    context: 800,
    hoverOver: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
}
