import styled, { keyframes } from 'styled-components'

const fillStyle = `
  position: fixed;
  left: 240px;
  top: 0;
  width: 88%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledSpinnerContainer = styled.div`
  display: inline-block;
  &[data-variant='fill'] {
    ${fillStyle};
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 3147483647;
  }
  &[data-variant='fullScreen'] {
    ${fillStyle};
    /* height: ${(props) =>
      typeof window !== undefined && document.querySelector('.app_wrapper')
        ? `${document.querySelector('.app_wrapper').clientHeight}px`
        : '100%'}; */
    height: 100vh;
    z-index: 3147483647;
  }
  &[data-variant='block'] {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 80px;
  }
  &[data-variant='normal'] {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
